import { defineStore } from 'pinia';
import moment from 'moment';

import SensorDataService from '@/services/SensorDataService';
import type SensorDataInterface from '@/interfaces/SensorDataInterface';
import type SensorDataFilterInterface from '@/interfaces/SensorDataFilterInterface';

import useSensorStore from '@/store/SensorStore';
import type SensorInterface from '@/interfaces/SensorInterface';

/** Config State */
interface SensorDataState {
  /** Sensor array */
  _measurements: SensorDataInterface[];
}

const parseMeasurements = (m: SensorDataInterface[], type: string): any => {
  const store = useSensorStore();

  return m.reduce((r, a: SensorDataInterface) => {
    // @ts-expect-error
    const sensor = store.sensor(a.sensorId);
    const identifier = sensor.name || sensor.deveui;

    r[identifier] = r[identifier] || [];
    r[identifier].push({
      x: moment(a.recorded_time),
      y: (a.data as any)[type],
    });
    return r;
  }, Object.create({}));
};

/** Config Store */
export default defineStore('sensor-data', {
  // Default Config State
  state: (): SensorDataState => ({
    _measurements: [],
  }),
  // Getters
  getters: {
    measurements: (s): SensorDataInterface[] => s._measurements,
    measurement:
      (s): object =>
      (measurementId: string) =>
        s._measurements.find(measurement => measurement.id === measurementId),
    temperatureMeasurements: (s): any => {
      return parseMeasurements(s._measurements, 'temperature');
    },
    humidityMeasurements: (s): any => {
      return parseMeasurements(s._measurements, 'humidity');
    },
    co2Measurements: (s): any => {
      return parseMeasurements(s._measurements, 'CO2Concentration');
    },
    illuminanceMeasurements: (s): any => {
      return parseMeasurements(s._measurements, 'illuminance');
    },
    exportableMeasurements: (s): any => {
      const store = useSensorStore();

      return s._measurements.reduce((r, a: SensorDataInterface) => {
        // @ts-expect-error
        const sensor: SensorInterface = store.sensor(a.sensorId);
        const identifier: string = sensor.name
          ? `${sensor.name} - ${sensor.deveui || ''}`
          : sensor.deveui || '';

        r[identifier] = r[identifier] || [];
        r[identifier].push({
          timestamp: moment(a.recorded_time).format('DD-MM-YYYY HH:mm:ss'),
          temperature: a.data.temperature,
          humidity: a.data.humidity,
          co2: a.data.CO2Concentration,
          illuminance: a.data.illuminance,
          motion: a.data.motion,
          voltage: a.data.batteryVoltage,
        });
        return r;
      }, Object.create({}));
    },
  },
  // Actions
  actions: {
    /** Find all filters sensor data */
    async findAll(filters: SensorDataFilterInterface): Promise<void> {
      try {
        const measurements = await SensorDataService.findAll(filters);
        this._measurements = measurements.data.result;
      } catch (e: any) {
        throw new Error(e);
      }
    },
  },
});
