import http, { authHeader } from '@/http';
import type SensorInterface from '@/interfaces/SensorInterface';

export default {
  async find() {
    return await http.get('sensors/find', {
      headers: authHeader(),
    });
  },

  async update(sensor: SensorInterface) {
    return await http.put('sensors/update', sensor, {
      headers: authHeader(),
    });
  },
};
