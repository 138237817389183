import axios, { type AxiosInstance } from 'axios';

import router from '@/router';
import { useAuth } from '@/store';

const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL ?? '/',
  headers: {
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.response.data?.short_code !== 'invalid_credentials'
    ) {
      const authStore = useAuth();
      authStore.logout();
      await router.replace('/');
    }
    return await Promise.reject(error);
  }
);

export const authHeader = (): object => {
  // return auth header with jwt if user is logged in and request is to the api url
  const store = useAuth();
  if (store.isAuthenticated && store.token) {
    return { Authorization: `Bearer ${store.token}` };
  } else {
    return {};
  }
};

export default apiClient;
