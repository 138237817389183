/**
 * Vue3 Main script
 */

// Load vue core
import { createApp } from 'vue';
import VueMapboxTs from 'vue-mapbox-ts';
import VCalendar from 'v-calendar';

import router from './router';
import store from './store';

// Load Vuetify
import vuetify from './plugins/vuetify';

// Load Layout vue.
import App from './App.vue';

// Load styles
import 'v-calendar/style.css';

/** Register Vue */
const vue = createApp(App);
vue.use(router);
vue.use(store);
vue.use(vuetify);
vue.use(VueMapboxTs);
vue.use(VCalendar, {});
vue.mount('#app');
