import type UserLoginInterface from '@/interfaces/UserLoginInterface';
import AuthService from '@/services/AuthService';
import { defineStore } from 'pinia';

/** Config State */
interface AuthState {
  /** Authentication token */
  _token: string | null;
  /** User info */
  _user: object;
}

/** Config Store */
export default defineStore('auth', {
  // Default Config State
  state: (): AuthState => ({
    _token: null,
    _user: {},
  }),
  // Getters
  getters: {
    isAuthenticated: (s): boolean => !!s._token,
    token: (s): string | null => s._token,
    user: (s): object => s._user,
  },
  // Actions
  actions: {
    /** Log the user in */
    async login(user: UserLoginInterface): Promise<any> {
      try {
        const auth = await AuthService.login(user);
        this.setToken(auth.data.access_token);
        this.setUser({
          name: auth.data.name,
          email: auth.data.email,
        });
        return auth;
      } catch (e: any) {
        throw new Error(e);
      }
    },
    /** Logout the user */
    logout(): void {
      this._token = null;
      this._user = {};
    },
    /** Save the authentication token */
    setToken(token: string): void {
      this._token = token;
    },
    /** Save the user details */
    setUser(user: object): void {
      this._user = user;
    },
  },
  // Data persistence destination
  persist: {
    key: import.meta.env.VITE_APP_WEBSTORAGE_NAMESPACE ?? 'vuetify',
    storage: window.sessionStorage,
  },
});
