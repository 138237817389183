<template>
  <v-toolbar-items class="hidden-sm-and-down">
    <v-btn
      v-for="item in menuItems"
      :key="item.title"
      :to="item.to"
      variant="flat"
    >
      <v-icon start dark>{{ item.icon }}</v-icon>
      {{ item.title }}
    </v-btn>
    <app-bar-menu-component />
  </v-toolbar-items>
</template>

<script setup lang="ts">
import AppBarMenuComponent from '@/components/AppBarMenuComponent.vue';
import type DrawerMenuItem from '@/interfaces/DrawerMenuItemInterface';

/** Drawer menu items */
const menuItems: DrawerMenuItem[] = [
  {
    title: 'Reports',
    icon: 'mdi-chart-bar',
    to: { name: 'Home' },
  },
  {
    title: 'Sensors',
    icon: 'mdi-leak',
    to: { name: 'Sensors' },
  },
];
</script>
