import http from '@/http';
import type UserLoginInterface from '@/interfaces/UserLoginInterface';

export default {
  async login(data: UserLoginInterface) {
    return await http.post('auth/login', data, {
      withCredentials: true,
    });
  },

  async register(data: any) {
    return await http.put('auth/register', data);
  },

  async logout() {
    return await http.post('auth/logout');
  },
};
