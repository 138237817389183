import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createPinia, type Pinia } from 'pinia';

// Pinia Stores

import useGlobal from '@/store/GlobalStore';
import useConfig from '@/store/ConfigStore';
import useAuth from '@/store/AuthStore';
import useSensor from '@/store/SensorStore';
import useSensorData from '@/store/SensorDataStore';

/** Pinia Store */
const pinia: Pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default pinia;

export { useConfig, useGlobal, useAuth, useSensor, useSensorData };
