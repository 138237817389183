import {
  createRouter,
  createWebHistory,
  type Router,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from 'vue-router';
import { nextTick } from 'vue';

// Pinia Store
import { useAuth, useGlobal } from '@/store';

/** Router Rules */
const routes: RouteRecordRaw[] = [
  { path: '/', redirect: { name: 'Home' } },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: async () => await import('@/views/BaseDashboardView.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: async () => await import('@/views/HomeView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'sensors',
        name: 'Sensors',
        component: async () => await import('@/views/sensor/SensorsView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'sensor/:id',
        name: 'Sensor',
        component: async () => await import('@/views/sensor/SensorView.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/auth/login',
    name: 'Auth',
    component: async () => await import('@/views/AuthView.vue'),
  },
];

/** Vue Router */
const router: Router = createRouter({
  /**
   * History Mode
   *
   * @see {@link https://router.vuejs.org/guide/essentials/history-mode.html }
   */
  history: createWebHistory(import.meta.env.BASE_URL), // createWebHashHistory(import.meta.env.BASE_URL)
  /*
  // Unimplemented in Vuetify 3.1.6
  scrollBehavior: (to, _from, savedPosition ) => {
    let scrollTo: number | string = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.top;
    }

    return goTo(scrollTo);
  },
  */
  routes,
});

// Global before guards
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards}
router.beforeEach(
  async (
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const globalStore = useGlobal();
    // Show Loading
    globalStore.setLoading(true);
    // Hide snack bar
    globalStore.setMessage('');
    await nextTick();

    // Open the auth store
    const authStore = useAuth();
    // Check if the user is logged in
    const isUserLoggedIn = authStore.isAuthenticated;

    if (_to.matched.some(record => record.meta.requiresAuth)) {
      if (!isUserLoggedIn) {
        // User is not logged in but trying to open page which requires
        authStore.logout();
        next({
          path: '/auth/login',
          query: { redirect: _to.fullPath },
        });
      } else {
        // User is logged in and trying to open page which requires auth
        next();
      }
    } else if (_to.name === 'Login' && isUserLoggedIn) {
      // User logged in but still trying to open login page
      next({ name: 'Home' });
    } else {
      // User is trying to open page which doesn't require auth
      next();
    }
  }
);

// Global After Hooks
// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks}
router.afterEach(() => {
  const globalStore = useGlobal();
  // Hide Loading
  globalStore.setLoading(false);
});

export default router;
