import { defineStore } from 'pinia';

import SensorService from '@/services/SensorService';
import type SensorInterface from '@/interfaces/SensorInterface';

/** Config State */
interface SensorsState {
  /** Sensor array */
  _sensors: SensorInterface[];
}

/** Config Store */
export default defineStore('sensors', {
  // Default Config State
  state: (): SensorsState => ({
    _sensors: [],
  }),
  // Getters
  getters: {
    sensors: (s): SensorInterface[] => s._sensors,
    sensor:
      (s): object =>
      (sensorId: string) =>
        s._sensors.find(sensor => sensor.id === sensorId),
  },
  // Actions
  actions: {
    /** Log the user in */
    async find(): Promise<any> {
      try {
        const sensors = await SensorService.find();
        this._sensors = sensors.data;
      } catch (e: any) {
        throw new Error(e);
      }
    },
    /** Log the user in */
    async update(sensor: SensorInterface): Promise<void> {
      try {
        await SensorService.update(sensor);

        const index = this._sensors.findIndex(
          _sensor => _sensor.id === sensor.id
        );
        if (index) {
          this._sensors[index] = sensor;
        }
      } catch (e: any) {
        throw new Error(e);
      }
    },
  },
});
