import http, { authHeader } from '@/http';

import type SensorDataFilterInterface from '@/interfaces/SensorDataFilterInterface';

export default {
  async find({ sensorId, sortBy, page, itemsPerPage }: any) {
    return await http.get('sensor-data/find', {
      headers: authHeader(),
      params: {
        sensorId,
        sortBy,
        pagination: {
          page,
          itemsPerPage,
        },
      },
    });
  },

  async findAll({ sensors, range }: SensorDataFilterInterface) {
    return await http.get('sensor-data/find-all', {
      headers: authHeader(),
      params: {
        sensors,
        range,
      },
    });
  },
};
